import { Button, Input, Box } from "@chakra-ui/react";
import { useRouter } from 'next/router'

const AddWallet = ({
  trackedWallets,
  setTrackedWallets,
}: {
  trackedWallets: string[];
  setTrackedWallets: any;
}) => {
  const router = useRouter();

  return (
    <Box>
      {trackedWallets.map((wallet, index) => (
        <Box mb="19px" key={index}>
          <Input
            width="auto"
            minW={{ base: "100%", md: "27em" }}
            m={0}
            value={wallet}
            color="#fff"
            letterSpacing={"-0.006em"}
            fontSize={{ base: "14px" }}
            lineHeight={{ base: "24px" }}
            fontWeight="400"
            border="1px solid #DDE2E4"
            placeholder="Enter Wallet Address"
            _placeholder={{
              color: "#fff",
              opacity: 0.4,
            }}
            onChange={(event) =>
              setTrackedWallets((wallets: string[]) => {
                const copy = [...wallets];
                copy[index] = event.target.value;
                return copy;
              })
            }
          />
        </Box>
      ))}
      <Box>
        <Button
          variant="link"
          color={router.pathname === '/signup' ? '#FFE7BF' : "#51A2DC"}
          fontWeight="400"
          fontSize="14px"
          lineHeight={"24px"}
          letterSpacing="-0.006em"
          mb="19px"
          onClick={() =>
            setTrackedWallets((wallets: string[]) => wallets.concat(""))
          }
        >
          + Add another wallet
        </Button>
      </Box>
    </Box>
  );
};

export default AddWallet;
