import { User, useUserContext } from "@lib/hooks/user";
import {
  Flex,
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Input,
  Box,
  Link,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { UpgradeSteps, UserPaymentStatusType } from "@lib/types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAPI, * as API from "@lib/hooks/api";
import { signInWithEthereum, usePayment } from "@lib/hooks/web3";
import { mutate } from "swr";
import AddWallet from "../add-wallet";

const InitialModal = ({ nextStep }: { nextStep: () => void }) => {
  const { user, isPaid } = useUserContext();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const { isSubmitting: isPaying, startPayment, error, hash } = usePayment();

  const skipIfPaidOrPending = useCallback(
    (user: User) => {
      if (
        user?.paymentStatus === UserPaymentStatusType.Paid ||
        user?.paymentStatus === UserPaymentStatusType.Pending
      ) {
        nextStep();
        return true;
      }
      return false;
    },
    [nextStep]
  );

  useEffect(() => {
    skipIfPaidOrPending(user);
  }, [skipIfPaidOrPending, user]);

  const handlePayment = async () => {
    if (!isPaid) {
      setIsSigningIn(true);
      try {
        await signInWithEthereum();
        const user = await mutate("/api/auth");
        setIsSigningIn(false);

        if (!skipIfPaidOrPending(user)) {
          try {
            await startPayment(user);

            await mutate("/api/auth");
            nextStep();
          } catch (err: any) {
            console.warn(err.message);
          }
        }
      } catch (err: any) {
        console.warn(err.message);
        setIsSigningIn(false);
      }
    }
  };

  return (
    <Box textAlign={"center"}>
      <Text
        color="#C2C9CE"
        fontSize={"16px"}
        lineHeight="24px"
        fontWeight={400}
        letterSpacing="-0.011em"
        textAlign={"center"}
        m="0 auto 35px"
        maxW="515px"
      >
        This functionality is only availale for Scottie Pro users. <br /> Pro
        currently costs 0.08Ξ per year and includes the following features
      </Text>
      {/* <Text>{error}</Text> */}

      <Box mb="14px" p="20px" bg="#17202A" borderRadius={"6px"}>
        <Text
          color="#C2C9CE"
          fontSize={"14px"}
          lineHeight="17px"
          fontWeight={500}
          textAlign={"center"}
          mb="14px"
        >
          <span style={{ marginRight: "10px" }}>✉️️</span> Email Updates
        </Text>
        <Text
          color="#C2C9CE"
          fontSize={"13px"}
          lineHeight="16px"
          fontWeight={500}
          textAlign={"center"}
          maxW="282px"
          m="0 auto"
        >
          Get notified as soon as Scottie notices that you need to take an
          action.
        </Text>
      </Box>

      <Box mb="14px" p="20px" bg="#17202A" borderRadius={"6px"}>
        <Text
          color="#C2C9CE"
          fontSize={"14px"}
          lineHeight="17px"
          fontWeight={500}
          textAlign={"center"}
          mb="14px"
        >
          <span style={{ marginRight: "10px" }}>⛽️</span> Gwei Based Reminders
        </Text>
        <Text
          color="#C2C9CE"
          fontSize={"13px"}
          lineHeight="16px"
          fontWeight={500}
          textAlign={"center"}
          maxW="282px"
          m="0 auto"
        >
          Want to wait to claim until gas is lower? Just set a reminder and
          Scottie will notify you.
        </Text>
      </Box>

      <Box mb="14px" p="20px" bg="#17202A" borderRadius={"6px"}>
        <Text
          color="#C2C9CE"
          fontSize={"14px"}
          lineHeight="17px"
          fontWeight={500}
          textAlign={"center"}
          mb="14px"
        >
          <span style={{ marginRight: "10px" }}>🌱️</span> Track Unreleased
          Projects
        </Text>
        <Text
          color="#C2C9CE"
          fontSize={"13px"}
          lineHeight="16px"
          fontWeight={500}
          textAlign={"center"}
          maxW="282px"
          m="0 auto"
        >
          Want to track a project that is pre-mint? Just feed Scottie it’s
          twitter handle.
        </Text>
      </Box>

      <Box mb="14px" p="20px" bg="#17202A" borderRadius={"6px"}>
        <Text
          color="#C2C9CE"
          fontSize={"14px"}
          lineHeight="17px"
          fontWeight={500}
          textAlign={"center"}
          mb="14px"
        >
          <span style={{ marginRight: "10px" }}>🤑️</span> Track Multiple
          Wallets
        </Text>
        <Text
          color="#C2C9CE"
          fontSize={"13px"}
          lineHeight="16px"
          fontWeight={500}
          textAlign={"center"}
          maxW="282px"
          m="0 auto"
        >
          Have Scottie track multiple projects and manage notification settings
          for them.
        </Text>
      </Box>

      <Box mb="14px" p="20px" bg="#17202A" borderRadius={"6px"}>
        <Text
          color="#C2C9CE"
          fontSize={"14px"}
          lineHeight="17px"
          fontWeight={500}
          textAlign={"center"}
          mb="14px"
        >
          <span style={{ marginRight: "10px" }}>🎟️</span> Scottie Founders Pass
        </Text>
        <Text
          color="#C2C9CE"
          fontSize={"13px"}
          lineHeight="16px"
          fontWeight={500}
          textAlign={"center"}
          maxW="282px"
          m="0 auto"
        >
          The first 2,500 Scottie Pro users will be able to claim a founders NFT
          with special perks.
        </Text>
      </Box>

      <Button
        mt="34px"
        colorScheme="blue"
        isLoading={isSigningIn || isPaying}
        onClick={handlePayment}
        bg="#4094F7"
        borderRadius={"6px"}
        color="#F6F8F9"
        fontSize={"17px"}
        lineHeight="24px"
        fontWeight={600}
        letterSpacing="-0.006em"
        p="8px 14px"
        _hover={{
          opacity: 0.7,
          bg: "#4094F7",
          color: "#F6F8F9",
        }}
        _active={{
          opacity: 0.7,
          bg: "#4094F7",
          color: "#F6F8F9",
        }}
        _focus={{
          opacity: 0.7,
          bg: "#4094F7",
          color: "#F6F8F9",
        }}
      >
        Upgrade to Pro
      </Button>
    </Box>
  );
};

const PendingModal = ({ nextStep }: { nextStep: () => void }) => {
  const { user, isPaid } = useUserContext();

  const { data, error } = useAPI(
    user.paymentStatus === UserPaymentStatusType.Pending
      ? `/api/transaction/validate`
      : null,
    {
      refreshInterval: 500,
    }
  );

  const hash = user.transactions?.[0]?.hash;

  useEffect(() => {
    if (isPaid) {
      return nextStep();
    }
  }, [isPaid, nextStep]);

  return (
    <Box textAlign={"center"}>
      {hash && <Text>Hash: {hash}</Text>}
      {error && <Text>{error.message}</Text>}

      <Text
        color="#C2C9CE"
        fontSize={"13px"}
        lineHeight="16px"
        fontWeight={400}
        textAlign={"center"}
        maxW="282px"
        m="0 auto"
      >
        Payment Transaction is Pending <br /> View Transaction Hash{" "}
        <Link
          href="#"
          color="#4094F7"
          fontSize={"13px"}
          lineHeight="16px"
          fontWeight={400}
        >
          here
        </Link>
      </Text>

      <Button
        mt="34px"
        colorScheme="gray"
        bg="rgba(255, 255, 255, 0.32)"
        borderRadius={"6px"}
        color="#F6F8F9"
        fontSize={"17px"}
        lineHeight="24px"
        fontWeight={600}
        letterSpacing="-0.006em"
        p="8px 14px"
        _hover={{
          opacity: 0.7,
          bg: "rgba(255, 255, 255, 0.32)",
          color: "#F6F8F9",
        }}
        _active={{
          opacity: 0.7,
          bg: "rgba(255, 255, 255, 0.32)",
          color: "#F6F8F9",
        }}
        _focus={{
          opacity: 0.7,
          bg: "rgba(255, 255, 255, 0.32)",
          color: "#F6F8F9",
        }}
        onClick={nextStep}
      >
        Please Wait
      </Button>
    </Box>
  );
};

const SuccessModal = ({ nextStep }: { nextStep: () => void }) => {
  return (
    <Box textAlign={"center"}>
      <Text
        color="#C2C9CE"
        fontSize={"13px"}
        lineHeight="16px"
        fontWeight={400}
        textAlign={"center"}
        maxW="282px"
        m="0 auto"
      >
        Congrats and welcome to Scottie Pro. <br /> Your plan will{" "}
        <strong>not</strong> auto-renew.
      </Text>

      <Button
        mt="34px"
        colorScheme="blue"
        onClick={nextStep}
        bg="#4094F7"
        borderRadius={"6px"}
        color="#F6F8F9"
        fontSize={"17px"}
        lineHeight="24px"
        fontWeight={600}
        letterSpacing="-0.006em"
        p="8px 14px"
        minW={"151px"}
        _hover={{
          opacity: 0.7,
          bg: "#4094F7",
          color: "#F6F8F9",
        }}
        _active={{
          opacity: 0.7,
          bg: "#4094F7",
          color: "#F6F8F9",
        }}
        _focus={{
          opacity: 0.7,
          bg: "#4094F7",
          color: "#F6F8F9",
        }}
      >
        Continue
      </Button>
    </Box>
  );
};

const EmailModal = ({ nextStep }: { nextStep: () => void }) => {
  const { user } = useUserContext();
  const [email, setEmail] = useState(user.email);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await API.post("/api/user/email", {
      userId: user.id,
      email,
    });
    setSubmitting(false);
    nextStep();
  };

  return (
    <Box textAlign="center">
      <Text
        color="#C2C9CE"
        fontSize={"13px"}
        lineHeight="16px"
        fontWeight={400}
        textAlign={"center"}
        maxW="515px"
        m="0 auto"
      >
        One of the perks of Pro is getting email notificaitons when Scottie
        finds a relevant announcement. Enter your email for notifications.
      </Text>

      <Input
        type="email"
        width="auto"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="Enter Email"
        m="35px auto 35px"
        border="1px solid #A6B5BD"
        borderRadius={"6px"}
        w="366px"
        p="8px 16px"
        color="rgba(255, 255, 255, 0.6)"
        letterSpacing={"-0.006em"}
        fontWeight="400"
        fontSize={"14px"}
        lineHeight="24px"
      />
      <Box display="flex" alignItems="center" justifyContent={"center"}>
        <Button
          colorScheme="gray"
          disabled={!email}
          isLoading={submitting}
          onClick={() => handleSubmit()}
          bg="#37485B"
          borderRadius={"6px"}
          color="#F6F8F9"
          fontSize={"17px"}
          lineHeight="24px"
          fontWeight={600}
          letterSpacing="-0.006em"
          p="8px 14px"
          minW={"151px"}
          _hover={{
            opacity: 0.7,
            bg: "#37485B",
            color: "#F6F8F9",
          }}
          _active={{
            opacity: 0.7,
            bg: "#37485B",
            color: "#F6F8F9",
          }}
          _focus={{
            opacity: 0.7,
            bg: "#37485B",
            color: "#F6F8F9",
          }}
        >
          Confirm
        </Button>
        <Button
          colorScheme="blue"
          onClick={nextStep}
          bg="rgba(64, 148, 247, 0.7)"
          borderRadius={"6px"}
          color="#F6F8F9"
          fontSize={"17px"}
          lineHeight="24px"
          fontWeight={600}
          letterSpacing="-0.006em"
          p="8px 14px"
          minW={"151px"}
          ml="12px"
          _hover={{
            opacity: 0.7,
            bg: "rgba(64, 148, 247, 0.7)",
            color: "#F6F8F9",
          }}
          _active={{
            opacity: 0.7,
            bg: "rgba(64, 148, 247, 0.7)",
            color: "#F6F8F9",
          }}
          _focus={{
            opacity: 0.7,
            bg: "rgba(64, 148, 247, 0.7)",
            color: "#F6F8F9",
          }}
        >
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export const WalletModal = ({ nextStep }: { nextStep: () => void }) => {
  const { user } = useUserContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trackedWallets, setTrackedWallets] = useState<string[]>([]);

  useEffect(() => {
    if (user && user.primaryWallet) {
      setTrackedWallets([user.primaryWallet as string]);
    }
  }, [user]);

  const handleAddWallet = async () => {
    setIsSubmitting(true);
    await API.post("/api/track/wallet", {
      userId: user.id,
      addresses: trackedWallets,
    });
    nextStep();
    setIsSubmitting(false);
  };
  return (
    <Box>
      <Flex direction="column">
        <Text
          fontWeight="700"
          fontSize="20px"
          lineHeight={"24px"}
          letterSpacing="-0.022em"
          color="#fff"
          mb="19px"
        >
          <span style={{ marginRight: "15px" }}>👜</span>Tracked Wallets
        </Text>

        <AddWallet
          trackedWallets={trackedWallets}
          setTrackedWallets={setTrackedWallets}
        />

        <Button
          colorScheme="blue"
          onClick={handleAddWallet}
          isLoading={isSubmitting}
          bg="rgba(64, 148, 247, 0.4)"
          color="#F6F8F9"
          fontWeight="600"
          fontSize="14px"
          lineHeight={"24px"}
          letterSpacing="-0.006em"
          w="128px"
        >
          Confirm
        </Button>
      </Flex>
    </Box>
  );
};

const UpgardeModal = ({
  showUpgradeModal,
  setShowUpgradeModal,
}: {
  showUpgradeModal: boolean;
  setShowUpgradeModal: any;
}) => {
  const [step, setStep] = useState(UpgradeSteps.Initial);
  const router = useRouter();

  const { title, body } = useMemo<{ title: string; body: ReactElement }>(() => {
    switch (step) {
      case UpgradeSteps.Initial:
        return {
          title: "🚀  Pro Functionality",
          body: <InitialModal nextStep={() => setStep(UpgradeSteps.Pending)} />,
        };
      case UpgradeSteps.Pending:
        return {
          title: "🕦 Pending... ",
          body: <PendingModal nextStep={() => setStep(UpgradeSteps.Success)} />,
        };
      case UpgradeSteps.Success:
        return {
          title: "🚀 Success ",
          body: <SuccessModal nextStep={() => setStep(UpgradeSteps.Wallet)} />,
        };
      case UpgradeSteps.Email:
        return {
          title: "✉️  Enter Email",
          body: (
            <EmailModal
              nextStep={() => {
                router.push("/");
                setShowUpgradeModal(false);
              }}
            />
          ),
        };
      case UpgradeSteps.Wallet:
        return {
          title: "💵  Enter Additional Wallets",
          body: <WalletModal nextStep={() => setStep(UpgradeSteps.Email)} />,
        };
    }
  }, [setShowUpgradeModal, step, router]);

  return (
    <Modal
      isOpen={showUpgradeModal}
      onClose={() => {
        if (router.pathname === "/signup") {
          return;
        }
        setShowUpgradeModal(false);
      }}
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.79)" />
      <ModalContent
        bg="#232F3D"
        w={{ base: "100%", md: "540px", lg: "679px" }}
        maxW={{ base: "100%", md: "540px", lg: "679px" }}
        boxShadow="0px 0px 1px rgba(0, 0, 0, 0.32)"
        borderRadius={"6px"}
        p={{ base: "20px", md: "24px", lg: "32px" }}
      >
        <ModalHeader
          color="#fff"
          letterSpacing={"-0.014em"}
          fontSize={{ base: "18px" }}
          lineHeight={{ base: "24px" }}
          fontWeight="600"
          p="0"
          mb="15px"
          textAlign={"center"}
        >
          {title}
        </ModalHeader>
        {UpgradeSteps.Initial && (
          <ModalCloseButton
            color="#fff"
            right="25px"
            top="25px"
            _active={{
              outline: "none",
            }}
            _focus={{
              outline: "none",
            }}
          />
        )}
        <ModalBody p="0">{body}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const useUpgrade = () => {
  const { isPaid } = useUserContext();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const renderModal = useCallback(() => {
    return (
      <UpgardeModal
        showUpgradeModal={showUpgradeModal}
        setShowUpgradeModal={setShowUpgradeModal}
      />
    );
  }, [showUpgradeModal]);

  return {
    renderModal,
    upgrade: useCallback(
      (callback?: any) => {
        return () => {
          if (isPaid && callback) {
            return callback();
          }
          setShowUpgradeModal(true);
        };
      },
      [isPaid]
    ),
  };
};
