import { Box, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { ethers } from "ethers";
import config from "@src/config";
import { User, useUserContext } from "@lib/hooks/user";
import useAPI, { post } from "@lib/hooks/api";
import { AmplitudeEvents, UserPaymentStatusType } from "@lib/types";
import { SiweMessage } from "siwe";
import * as API from "@lib/hooks/api";
import { logEvent, identify, Identify } from "@amplitude/analytics-browser";

async function createSiweMessage(address: string, statement: string) {
  const domain = window.location.host;
  const origin = window.location.origin;
  const { nonce, user } = await API.post(`/api/auth/nonce`, { address });
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: 1,
    nonce,
  });
  return message.prepareMessage();
}

export const signInWithEthereum = async () => {
  if ("ethereum" in window) {
    const { ethereum } = window as any;
    await ethereum.enable();
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    logEvent(AmplitudeEvents.WalletConnected, { address });

    const userWalletProperties = new Identify();
    userWalletProperties.set("Wallet Address", address);
    identify(userWalletProperties);

    if (
      process.env.NODE_ENV === "production" &&
      !config.whitelistedAccounts.includes(address)
    ) {
      throw new Error("This ethereum account is not whitelisted");
    }

    const message = await createSiweMessage(
      address,
      "Sign in with Ethereum to the app."
    );
    const signature = await signer.signMessage(message);

    await API.post(`/api/auth/verify`, { message, signature });
  } else {
    throw new Error("wallet-not-installed");
  }
};

export const usePayment = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hash, setHash] = useState<string | null>(null);
  const [error, setError] = useState<any>();

  const startPayment = async (user: User) => {
    setIsSubmitting(true);
    try {
      if (!("ethereum" in window)) {
        throw new Error("install wallet");
      }

      const { ethereum } = window as any;

      await ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();

      const address = await signer.getAddress();

      if (address !== user?.primaryWallet) {
        throw new Error(
          `Connected address: ${address} must be same as primary wallet address ${user.primaryWallet}`
        );
      }

      logEvent(AmplitudeEvents.PaymentInitiated);

      const tx = await signer.sendTransaction({
        to: config.payment.address,
        value: ethers.utils.parseEther(config.payment.amount),
      });

      logEvent(AmplitudeEvents.PaymentSuccessful);

      const txn = await post("/api/transaction/create", {
        hash: tx.hash,
      });

      setHash(tx.hash);
    } catch (err: any) {
      setError(err.message);
      setIsSubmitting(false);
      throw err;
    }
    setIsSubmitting(false);
  };

  return { isSubmitting, startPayment, error, hash };
};
